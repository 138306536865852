import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const PreviewCompatibleImage = ({ image, alt, ...props }) => {
  if (!image) return null

  const { childImageSharp } = image

  if (!!childImageSharp) {
    return (
      <GatsbyImage image={getImage(image)} alt={alt || "Image"} {...props} />
    )
  }

  if (!!image && typeof image === "string") {
    return <img src={image} alt={alt || "Image"} {...props} />
  }

  return null
}

PreviewCompatibleImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string,
}

export default PreviewCompatibleImage
