import React from "react"

const FancyUnderline = () => {
  return (
    <div
      className="border-b-2 mt-2 border-black absolute inline-block"
      style={{
        width: "50%",
        marginLeft: "-25%",
      }}
    />
  )
}

export default FancyUnderline
