import React from "react"
import { Content } from "./content"
import PropTypes from "prop-types"

const MarkdownIntroSection = ({ html, contentComponent }) => {
  const ContentComponent = contentComponent || Content
  return (
    <section className="container mx-auto px-5 text-center mt-8 markdown lg:w-2/3">
      <ContentComponent content={html} />
    </section>
  )
}

MarkdownIntroSection.defaultProps = {
  html: "",
  contentComponent: null,
}

MarkdownIntroSection.propTypes = {
  html: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default MarkdownIntroSection
