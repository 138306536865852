import Layout from "../components/layout"
import Seo from "../components/seo"
import React from "react"
import PropTypes from "prop-types"
import { HTMLContent } from "../components/content"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import PreviewCompatibleImage from "../components/preview-compatible-image"
import FancyUnderline from "../components/fancy-underline"
import MarkdownIntroSection from "../components/markdown-intro"

const TitleSection = ({ title }) => {
  return (
    <section className="container mx-auto mt-4 px-5 text-center">
      <div className="inline-block relative pb-2">
        <p className="text-4xl font-extralight">{title}</p>
        <FancyUnderline />
      </div>
    </section>
  )
}

TitleSection.defaultProps = {
  title: "",
}

TitleSection.propTypes = {
  title: PropTypes.string,
}

const ContactCardSection = ({ persons }) => {
  return !persons ? null : (
    <section className="container mx-auto px-5 mt-8 flex flex-wrap justify-center">
      {persons.map((person, index) => (
        <div key={person.name + index} className="flex flex-col mx-8 my-8">
          {!person.image ? (
            <div className="flex-grow" />
          ) : (
            <div className="flex justify-center">
              <PreviewCompatibleImage
                alt={person.imageAltText || person.name}
                image={person.image}
                className="mb-4"
              />
            </div>
          )}
          <h1>{person.name || ""}</h1>
          {!!person.name && <hr className="border border-black my-4" />}
          <p className="text-xl font-light">{person.description || ""}</p>
          {!person.email ? (
            <div className="my-3" />
          ) : (
            <a
              className="transition hover:text-gray-600 inline-flex items-center"
              href={`mailto:${person.email}`}
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              {person.email}
            </a>
          )}
          {!person.phone ? (
            <div className="my-3" />
          ) : (
            <a
              className="transition hover:text-gray-600 inline-flex items-center"
              href={`tel:${person.phone}`}
            >
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              {person.phone}
            </a>
          )}
        </div>
      ))}
    </section>
  )
}

ContactCardSection.propTypes = {
  persons: PropTypes.arrayOf(
    PropTypes.shape({
      imageAltText: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      name: PropTypes.string,
      description: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    })
  ),
}

export const ContactPageTemplate = ({ title, introMD, persons, preview }) => {
  return (
    <>
      <TitleSection title={title} />
      <MarkdownIntroSection
        html={introMD}
        contentComponent={!preview && HTMLContent}
      />
      <ContactCardSection persons={persons} />
    </>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  introMD: PropTypes.string,
  persons: PropTypes.arrayOf(
    PropTypes.shape({
      imageAltText: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      name: PropTypes.string,
      description: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    })
  ),
}

const ContactPage = props => {
  return (
    <Layout>
      <Seo title={props.title} description={props.description} />
      <ContactPageTemplate {...props} title={props.title} />
    </Layout>
  )
}

export default ContactPage
