import React from "react"
import { graphql } from "gatsby"
import ContactPage from "../../templates/contact-page"

const Contact = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  return <ContactPage {...frontmatter} />
}

export const ContactEnQuery = graphql`
  query ContactEn {
    markdownRemark(
      fields: { slug: { eq: "/contact/" }, langKey: { eq: "en" } }
    ) {
      frontmatter {
        title
        description
        introMD
        persons {
          description
          image {
            childImageSharp {
              gatsbyImageData(height: 300, width: 500, placeholder: BLURRED)
            }
          }
          email
          imageAlt
          name
          phone
        }
      }
    }
  }
`

export default Contact
